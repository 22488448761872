// package import

import React from "react";
import { RootState } from "../redux/reducers/index";
import { useSelector } from "react-redux";
import convertUNIXString from "../utils/convertUNIXTimeStamp";

import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Table from 'react-bootstrap/Table';

// component

export const EventDetails: React.FC = () => {
  const getEventStatus = useSelector((state: RootState) => state.getEvent);

  // render

  return (
    <>
      <div className="event-details">
        <p>
          When : 
          <b>
            {convertUNIXString(
              getEventStatus.eventData && getEventStatus.eventData.dateTime,
              1
            )}{" "}
          </b>
        </p>
        <p>
          Where : 
          <b>
            {getEventStatus.eventData &&
              getEventStatus.eventData.addressLocation}
          </b>
        </p>
        <p>
          Code : 
          <b>
          {getEventStatus.eventData && getEventStatus.eventData.eventCode}
            
          </b>
        </p>
         <Tabs
          defaultActiveKey="men"
          id="justify-tab-example"
          className="mb-3"
          justify
          style={{backgroundColor: '#e8edf2', color: 'black'}}
        >
          <Tab style={{backgroundColor: '#A2E1EC'}} eventKey="men" title="Men">
            <Table style={{backgroundColor: '#A2E1EC'}} striped>
              <thead>
                <tr>
                  <th>Singles</th>
                  <th>Divorced</th>
                  <th>Married</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {getEventStatus.eventMembers &&
                      getEventStatus.eventMembers.men.singles.length
                      ? " " +
                      getEventStatus.eventMembers.men.singles.length +
                      " "
                      : ""}
                    <br />

                    {getEventStatus.eventMembers &&
                      getEventStatus.eventMembers.men.singles.length
                      ? "Between " +
                      Math.min(...getEventStatus.eventMembers.men.singles) +
                      "-" +
                      Math.max(...getEventStatus.eventMembers.men.singles) + " years"
                      : ""}
                  </td>
                  <td>
                    {getEventStatus.eventMembers &&
                      getEventStatus.eventMembers.men.divorced.length
                      ? " " +
                      getEventStatus.eventMembers.men.divorced.length +
                      " "
                      : ""}
                    <br />

                    {getEventStatus.eventMembers &&
                      getEventStatus.eventMembers.men.divorced.length
                      ? "Between " +
                      Math.min(...getEventStatus.eventMembers.men.divorced) +
                      "-" +
                      Math.max(...getEventStatus.eventMembers.men.divorced) + " years"
                      : ""}
                  </td>
                  <td>
                    {getEventStatus.eventMembers &&
                      getEventStatus.eventMembers.men.married.length
                      ? " " +
                      getEventStatus.eventMembers.men.married.length +
                      " "
                      : ""}
                    <br />

                    {getEventStatus.eventMembers &&
                      getEventStatus.eventMembers.men.married.length
                      ? "Between " +
                      Math.min(...getEventStatus.eventMembers.men.married) +
                      "-" +
                      Math.max(...getEventStatus.eventMembers.men.married) + " years"
                      : ""}
                  </td>
                </tr>

              </tbody>
            </Table>
          </Tab>
          <Tab style={{backgroundColor: '#FFDCEF', color: 'black'}} eventKey="women" title="Women">
            <Table style={{backgroundColor: '#FFDCEF'}} striped>
              <thead>
                <tr>
                  <th>Singles</th>
                  <th>Divorced</th>
                  <th>Married</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {getEventStatus.eventMembers &&
                      getEventStatus.eventMembers.women.singles.length
                      ? " " +
                      getEventStatus.eventMembers.women.singles.length +
                      " "
                      : ""}
                    <br />
                    {getEventStatus.eventMembers &&
                      getEventStatus.eventMembers.women.singles.length
                      ? "Between " +
                      Math.min(...getEventStatus.eventMembers.women.singles) +
                      "-" +
                      Math.max(...getEventStatus.eventMembers.women.singles) + " years"
                      : ""}
                  </td>
                  <td>
                    {getEventStatus.eventMembers &&
                      getEventStatus.eventMembers.women.divorced.length
                      ? " " +
                      getEventStatus.eventMembers.women.divorced.length +
                      " "
                      : ""}
                    <br />
                    {getEventStatus.eventMembers &&
                      getEventStatus.eventMembers.women.divorced.length
                      ? "Between " +
                      Math.min(...getEventStatus.eventMembers.women.divorced) +
                      "-" +
                      Math.max(...getEventStatus.eventMembers.women.divorced) + " years"
                      : ""}
                  </td>
                  <td>
                    {getEventStatus.eventMembers &&
                      getEventStatus.eventMembers.women.married.length
                      ? " " +
                      getEventStatus.eventMembers.women.married.length +
                      " "
                      : ""}
                    <br />
                    {getEventStatus.eventMembers &&
                      getEventStatus.eventMembers.women.married.length
                      ? "Between " +
                      Math.min(...getEventStatus.eventMembers.women.married) +
                      "-" +
                      Math.max(...getEventStatus.eventMembers.women.married) + " years"
                      : ""}
                  </td>
                </tr>

              </tbody>
            </Table>
          </Tab>

        </Tabs>
        {/* <p>
          Men:
          <b>
            {getEventStatus.eventMembers &&
              getEventStatus.eventMembers.men.singles.length
              ? " " +
              getEventStatus.eventMembers.men.singles.length +
              " singles," +
              Math.min(...getEventStatus.eventMembers.men.singles) +
              "-" +
              Math.max(...getEventStatus.eventMembers.men.singles)
              : ""}
            {getEventStatus.eventMembers &&
              getEventStatus.eventMembers.men.married.length
              ? " " +
              getEventStatus.eventMembers.men.married.length +
              " married," +
              Math.min(...getEventStatus.eventMembers.men.married) +
              "-" +
              Math.max(...getEventStatus.eventMembers.men.married)
              : ""}

            {getEventStatus.eventMembers &&
              getEventStatus.eventMembers.men.divorced.length
              ? " " +
              getEventStatus.eventMembers.men.divorced.length +
              " divorced," +
              Math.min(...getEventStatus.eventMembers.men.divorced) +
              "-" +
              Math.max(...getEventStatus.eventMembers.men.divorced)
              : ""}
          </b>
        </p> */}
        {/* <p>
          Women:
          <b>
            {getEventStatus.eventMembers &&
              getEventStatus.eventMembers.women.singles.length
              ? " " +
              getEventStatus.eventMembers.women.singles.length +
              " singles," +
              Math.min(...getEventStatus.eventMembers.women.singles) +
              "-" +
              Math.max(...getEventStatus.eventMembers.women.singles)
              : ""}

            {getEventStatus.eventMembers &&
              getEventStatus.eventMembers.women.married.length
              ? " " +
              getEventStatus.eventMembers.women.married.length +
              " married," +
              Math.min(...getEventStatus.eventMembers.women.married) +
              "-" +
              Math.max(...getEventStatus.eventMembers.women.married)
              : ""}

            {getEventStatus.eventMembers &&
              getEventStatus.eventMembers.women.divorced.length
              ? " " +
              getEventStatus.eventMembers.women.divorced.length +
              " divorced," +
              Math.min(...getEventStatus.eventMembers.women.divorced) +
              "-" +
              Math.max(...getEventStatus.eventMembers.women.divorced)
              : ""}
          </b>
        </p> */}
        <p>
          Matches:
          <b style={{ color: "rgb(228, 49, 147)" }}>
            {getEventStatus.matchesList && getEventStatus.matchesList.length}
          </b>
        </p>
      </div>
    </>
  );
};
